
    import Vue from 'vue'
    import VueCumulioDashboard from '@cumul.io/vue-cumulio-dashboard';
    import VueFullscreen from 'vue-fullscreen'
    import { DayPilot } from "daypilot-pro-vue";
    import { mapGetters } from "vuex";

    Vue.use(VueFullscreen)
    Vue.use(VueCumulioDashboard)
    export default {
        name: "Dashboards",
        components: {},
        data: () => ({
            tab: "",
            title: "",
            description: "",
            authKey: "",
            authToken: "",
            dashboardLanguage: "nl",
            dashboards: [],
            loading: false,
            fullscreen: false,
            automaticRefresh: true,
            refreshInterval: null,
            lastRefreshDate: null
        }),
        async mounted() {
            // await this.createNewCumulioIntegration()
            this.loading = true
            await this.getCumulioAuth()
            await this.getDashboards()
            if(this.dashboards.length > 0) {
                this.title = this.dashboards[0].name
                this.tab = this.dashboards[0].id
            }
            this.loading = false
            this.setDashboardLanguage()
            this.setAutomaticRefresh()
        },
        computed: {
            ...mapGetters(["language"]),
            todayWeekDay: function () {
                let today = DayPilot.Date.today().toString("dddd d", "nl-NL");
                today = today.charAt(0).toUpperCase() + today.slice(1); // First letter capital.
                return today;
            },
            todayMonthYear: function () {
                let today = DayPilot.Date.today().toString("MMMM yyyy", "nl-NL");
                today = today.charAt(0).toUpperCase() + today.slice(1); // First letter capital.
                return today;
            },

            weekNumber: function () {
                return DayPilot.Date.today().weekNumberISO();
            }
        },
        methods: {
            setAutomaticRefresh() {
                if(this.automaticRefresh) {
                    this.refreshInterval = setInterval(this.refreshData, 900000)
                } else {
                    clearInterval(this.refreshInterval);
                    return;
                }
            },
            toggleFullScreen () {
                this.fullscreen = !this.fullscreen
            },
            async createNewCumulioIntegration() {
                await this.$store.dispatch('api/createNewCumulioIntegration').then((response) => {
                    console.log('Integration response: ', response);
                }).catch(err => {
                    console.log(err, "error");
                    this.$q.notify({ message: `Fout bij het aanmaken van de integration.`, color: 'negative'})
                });
            },
            async getCumulioAuth() {
                await this.$store.dispatch('api/getCumulioAuth').then((response) => {
                    console.log('Get Cumulio Auth response: ', response);
                    this.authKey = response.data.authID;
                    this.authToken = response.data.authToken;
                }).catch(err => {
                    console.log(err, "error");
                    this.$q.notify({ message: `Fout bij de authenticatie.`, color: 'negative'})
                });
            },
            async getDashboards() {
                await this.$store.dispatch('api/getCumulioDashboards').then((response) => {
                    this.dashboards = response.data.rows
                }).catch(err => {
                    console.log(err, "error");
                    this.$q.notify({ message: `Fout bij het ophalen van de dashboards.`, color: 'negative'})
                });
                this.automaticRefreshDashboard()
                this.lastRefreshDate = DayPilot.Date.now().toString("dd-MM-yyyy HH:mm", "nl-NL");

            },
            setTitleAndDescription(dashboard) {
                this.title = dashboard.name
            }, 
            async refreshData () {
                await this.getCumulioAuth()
                if(this.$refs.cumulioDashboard == undefined || this.$refs.cumulioDashboard[0] == undefined) {
                    clearInterval(this.refreshInterval);
                    return;
                }
                this.$refs.cumulioDashboard[0].refreshData().then(() => {
                    this.$q.notify({ message: `${this.$t('dashboardConfig.messages.successfulReload')}`, color: 'positive'})
                    this.lastRefreshDate = DayPilot.Date.now().toString("dd-MM-yyyy HH:mm", "nl-NL");
                }).catch((error) => {
                    this.$q.notify({ message: `${this.$t('dashboardConfig.messages.failedReload')}`, color: 'negative'})
                    console.log(error);
                });
            },
            automaticRefreshDashboard() {
                this.refreshInterval = setInterval(this.refreshData, 900000)
            },
            async exportDashboard() {
                await this.$refs.cumulioDashboard[0].exportDashboard('pdf').then((value) => {
                    console.log('x')
                    console.log(value, " value");
                }).catch((error) => {
                    console.log(error);
                });
            },
            setDashboardLanguage() {
                if(this.language == "nl-NL") {
                    this.dashboardLanguage = 'nl'
                } else if (this.language == "en-GB") {
                    this.dashboardLanguage = 'en'
                }
            }
        },
        beforeRouteLeave (to, from , next) {
            clearInterval(this.refreshInterval)
            next()
        },
        watch: {
            'language': {
                handler() {
                    this.setDashboardLanguage()
                },
            }
        }
    }
